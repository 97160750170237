import { FC } from "react";
import { Row, ListGroup, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

interface Item {
    id_enlace: number;
    titulo: string;
    tipo: string;
    id_estatus: number;
    url: string;
}

interface ItemProps {
    items: Item[];
    titulo: string;
    tipo?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const downloadFile = async (id_enlace: number, filename: string) => {
    const fileUrl = `${API_URL}/files/${id_enlace}/${filename}`;
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
    }
};

const LeftSideBarWrapper: FC<ItemProps> = ({ items, titulo, tipo}) => {
    const { url } = useParams();
    return (
        <div className='leftSideBar'>
            <Row>
                <Col>
                    <h1>{titulo}</h1>
                </Col>
            </Row>

            <ListGroup>
                {items.map((ite: any, index: number) => {
                    switch (ite.tipo) {
                        case "info": case "titulo": case "acord": case "libro": 
                            return <ListGroup.Item className="pt-3 pb-3" key={index} href={`#${ite.url}`} as='a'>{ite.titulo}</ListGroup.Item>
                        case "file":    return <ListGroup.Item className="pt-3 pb-3" key={index} href={`#${ite.url}`} onClick={() => downloadFile(ite.id_enlace, ite.url)} as='a'>{ite.titulo}</ListGroup.Item>
                        case "url":     return <ListGroup.Item className="pt-3 pb-3" key={index} href={`${ite.url}`} as='a'>{ite.titulo}</ListGroup.Item>
                        case "carrera":     return <ListGroup.Item active={(url === ite.url) ? true : false} className="pt-3 pb-3" key={index} to={`/Oferta_Educativa/${ite.url}`} as={Link}>{ite.titulo}</ListGroup.Item>
                        default: return <></>
                    }
                }
                )}
            </ListGroup>
        </div>
    )
}

export default LeftSideBarWrapper;