import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";
import GoUpButton from "./GoUpButton";
import HeaderWrapper from "./HeaderWrapper";
import LeftSideBarWrapper from "./LeftSideBarWrapper";
import DOMPurify from "dompurify";
import AccordContent from "./Content/acord";

const API_URL = process.env.REACT_APP_API_URL;

interface carreraProps {
    titulo: string;
    descripcion: string;
    imagen: string;
    tsu: string;
    perfil: string;
    perfil_egreso: string;
    mision: string;
    vision: string;
    cuerpo: string;
    ventajas: string;
    contacto: string;
    atributos: string;
    objetivos: string;
    id_enlace: string;
    tipo: string;
}

const MaestriaPage: React.FC = () => {
    const { url } = useParams();
    const [sidebarMenuItems, setSidebarMenuItems] = useState<any[]>([]);
    const [infoCarrera, setInforCarrera] = useState<any>()
    const [info, setInfo] = useState<carreraProps>();
    const [sideBarTitle, setSideBarTitle] = useState("")
    const navigate = useNavigate()

    const sanitizeHtml = (html: string) => ({
        __html: DOMPurify.sanitize(html),
    });

    const getChilds = async (enlaceId: any) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/getChilds`,
                { id: enlaceId }
            )
            const items = response.data
            setSidebarMenuItems(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const getCarrera = async (carrera: string) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/getMaestria`,
                { carrera: carrera }
            )
            const items = response.data;
            setInforCarrera(items);
            setSidebarMenuItems(items);
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const getInfo = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/url/Maestrias_`)
            const items = response.data;
            setInfo(items);
            if (items.tipo === "titulo" || items.tipo === "acord") {
                await getChilds(items.id_enlace);
            }
        } catch (error) {
            //console.log('Error: ', error)
            navigate('/404');
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getInfo();
            if (url) {
                await getCarrera(url);
                const violation = document.getElementById("inicio");
                window.scrollTo({
                    top: violation?.offsetTop,
                    behavior: "smooth"
                });
            }
        };
        fetchData();
    }, [url]);

    return (
        <>
            <HeaderWrapper />
            <Container fluid>
                <Row>
                    {info && <>
                        <Col className='menuBox' md={3} >
                            <LeftSideBarWrapper tipo={(!url) ? "maestria" : ""} items={sidebarMenuItems} titulo={(!url) ? info.titulo : url.replaceAll("_", " ")} />
                        </Col>
                        <Col className='content-wrapper ps-5' md={6} tabIndex={0}>
                            {sidebarMenuItems.map((ite: any, index: number) => {
                                switch (ite.tipo) {
                                    case "info": return <div key={index} id={ite.url}>
                                        <Row id={ite.url}>
                                            <h2>{ite.titulo}</h2>
                                            <div dangerouslySetInnerHTML={sanitizeHtml(ite?.cuerpo ?? "")} />

                                        </Row> <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /> </div>
                                    </div>
                                    case "acord": return <div key={index} id={ite.url}><AccordContent url={ite.url} id_enlace={ite.id_enlace} titulo={ite.titulo} /> <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /></div></div>
                                    case "titulo": return (info.tipo === "acord") ? <div key={index} id={ite.url}><AccordContent url={ite.url} id_enlace={ite.id_enlace} titulo={ite.titulo} /><div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /></div></div>
                                        :
                                        <Row key={index} id={ite.url}>
                                            <h2>{ite.titulo}</h2>
                                            <p>Crear componente para los tipo perfil</p>
                                        </Row>
                                    default: <></>
                                }
                            }
                            )}
                        </Col>
                        <Col md={3} className='rightSideBar'></Col>
                        <GoUpButton />
                    </>

                    }
                </Row >
            </Container>
            <Footer />
        </>
    )
}

export default MaestriaPage;